export enum ECheckoutSteps {
  MESSAGE = 'message',
  QUESTIONS = 'questions',
  ADDONS = 'addons',
  INSURANCE_CUSTOM = 'insurance-custom', // formerly insurance
  INSURANCE_OUTDOORSY = 'insurance-outdoorsy', // formerly protection
  PAYMENT = 'payment',
  ADDITIONAL_PROTECTIONS = 'additional-protections',
  VERIFY_DMV = 'verify-dmv', // formerly verify
  VERIFY_ID = 'verify-id', // formerly id-verification
  ROADSIDE_ASSISTANCE = 'roadside-assistance',
  TRIP_INSURANCE = 'trip-insurance',
  INTERIOR_DAMAGE_PROTECTION = 'interior-damage-protection', // formerly damage-protection
  EXPERIENCES = 'experiences',
  CONFIRMATION = 'confirmation',
  QUOTE_SUMMARY = 'quote-summary',
  INFO = 'info', // combines message and addons
  CONTACT = 'contact',
  NEW = 'new',
}

/**
 * Maps the new step names to the legacy step names used by checkout-flow
 */
export enum NEW_STEP_NAMES_TO_LEGACY {
  'info' = 'info',
  'message' = 'message',
  'questions' = 'questions',
  'addons' = 'addons',
  'insurance-custom' = 'insurance',
  'insurance-outdoorsy' = 'protection',
  'verify-dmv' = 'verify',
  'verify-id' = 'id-verification',
  'roadside-assistance' = 'roadside-assistance',
  'trip-insurance' = 'trip-insurance',
  'interior-damage-protection' = 'damage-protection',
  'payment' = 'payment',
  'confirmation' = 'complete',
  'experiences' = 'experiences',
  'quote-summary' = 'quote-summary',
  'additional-protections' = 'additional-protections',
  'new' = 'new',
  'contact' = 'contact',
}

/**
 * Maps the server step name to an english readable version that can be used
 * at the start of event names
 */
export enum STEP_NAMES_FOR_EVENT {
  'message' = 'Message',
  'questions' = 'Questions',
  'addons' = 'Addons',
  'insurance-custom' = 'Custom insurance',
  'insurance-outdoorsy' = 'Outdoorsy insurance',
  'verify-dmv' = 'Verify dmv',
  'verify-id' = 'Verify id',
  'roadside-assistance' = 'Roadside assistance',
  'trip-insurance' = 'Trip insurance',
  'interior-damage-protection' = 'Interior damage protection',
  'payment' = 'payment',
  'confirmation' = 'Confirmation',
  'info' = 'Info',
  'experiences' = 'Experiences',
  'quote-summary' = 'Quote summary',
  'additional-protections' = 'Additional Protections',
  'contact' = 'contact',
  'new' = 'new',
}

export enum EStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

export enum ERoute {
  RESERVE = 'reserve',
  CHECKOUT = 'checkout',
}

export interface ICheckoutStep {
  __typename?: 'CheckoutStep' | undefined;
  name: ECheckoutSteps;
  order: number;
  status: EStatus;
}

export interface ICheckoutSteps {
  id: number;
  steps: ICheckoutStep[];
  imminent_departure?: boolean;
  readonly_insurance?: boolean;
  route?: ERoute;
}
