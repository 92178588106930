import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useRouter } from '@/hooks/useRouter';
import { TRootState } from '@/redux/rootReducer';
import { getCheckoutError } from '@/redux/selectors/checkout/error';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getQuoteId } from '@/redux/selectors/quote';
import { ECheckoutSteps } from '@/services/types/core/checkoutSteps';
import { getParamAsString } from '@/utility/queryParams';

import Header from './Header';

const HeaderContainer: React.FC<{ hasError?: boolean }> = ({ hasError }) => {
  const router = useRouter();
  const intl = useIntl();
  const listingSlug = useSelector<TRootState, string | undefined>(
    state => state.listing.data?.slug,
  );
  const queryParams = useSelector(getQueryParams);
  const quoteId = useSelector(getQuoteId);
  const quote = quoteId || queryParams.quote;
  const listingUrl = quote ? `${listingSlug}?quote=${quote}` : listingSlug;
  const step = getParamAsString(router.query.step);
  const bookingError = useSelector(getCheckoutError);

  // TODO: Integrate with API's steps
  const backLinkLabelByStep: Record<string, string> = {
    [ECheckoutSteps.NEW]: intl.formatMessage({
      defaultMessage: 'Back to listing page',
      id: '//Gljv',
      description: 'Checkout > Header > back link title',
    }),
    [ECheckoutSteps.CONTACT]: intl.formatMessage({
      defaultMessage: 'Back to listing page',
      id: '//Gljv',
      description: 'Checkout > Header > back link title',
    }),
    [ECheckoutSteps.QUESTIONS]: intl.formatMessage({
      defaultMessage: 'Back to Booking Request',
      id: 'E6Y3WF',
      description: 'Checkout > Header > back link title',
    }),
    [ECheckoutSteps.PAYMENT]: intl.formatMessage({
      defaultMessage: 'Back to Booking Request',
      id: 'E6Y3WF',
      description: 'Checkout > Header > back link title',
    }),
  };

  return (
    <Header
      key={String(bookingError)}
      hasError={hasError}
      backLinkUrl={listingUrl}
      backLinkTitle={step ? backLinkLabelByStep[step] : undefined}
    />
  );
};

export default HeaderContainer;
