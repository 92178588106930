import React, { useState } from 'react';

import bannerBlue from '@/assets/owner/owner_banner_blue.jpg';
import bannerGreen from '@/assets/owner/owner_banner_green.jpg';
import bannerYellow from '@/assets/owner/owner_banner_yellow.jpg';

interface HeroProps {
  bgImage?: string;
}

const defaultBanners: string[] = [bannerBlue.src, bannerGreen.src, bannerYellow.src];

const Hero: React.FC<HeroProps> = ({ bgImage }) => {
  const [randIndex] = useState(Math.floor(Math.random() * 3));

  return (
    <div className="flex flex-col items-center m-auto h-52 lg:h-80">
      <img
        src={bgImage || defaultBanners[randIndex]}
        className="object-cover w-full h-full"
        alt="Owner Banner"
      />
    </div>
  );
};

export default Hero;
