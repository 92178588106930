import React from 'react';
import { useIntl } from 'react-intl';

interface OwnerBioProps {
  title?: string;
  description?: string;
}

const OwnerBio: React.FC<OwnerBioProps> = ({ title, description }) => {
  const intl = useIntl();

  return (
    <div className="mt-8 mb-12 sm:my-12">
      <h1
        className="font-medium tracking-wide text-center h5 md:h3 md:text-left"
        data-testid="owner-title">
        {intl.formatMessage(
          { defaultMessage: 'About {title}', id: '2abZQc' },
          {
            title,
          },
        )}
      </h1>
      <p
        className="mt-4 overflow-hidden font-normal text-center body-base md:text-left"
        data-testid="owner-description">
        {description}
      </p>
    </div>
  );
};

export default OwnerBio;
