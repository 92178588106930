import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

export const getCheckoutError = createSelector<
  TRootState,
  TRootState['checkout'],
  TRootState['checkout']['error']
>(
  state => state.checkout,
  checkout => checkout.error,
);
