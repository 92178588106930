import React from 'react';
import { useIntl } from 'react-intl';

import Rating from '@/components/route/listing/Rating/Rating';
import Divider from '@/components/switchback/Divider/Divider';
import { IAverageRatings } from '@/services/types/search/rentals/id';
import { IUserResponseAttributes } from '@/services/types/search/users/id';
import formatResponseTime from '@/utility/formatResponseTime';

interface OwnerInfoInterface {
  attributes: IUserResponseAttributes;
  averageRating?: IAverageRatings;
}

const OwnerInfo: React.FC<OwnerInfoInterface> = ({ attributes, averageRating }) => {
  const intl = useIntl();
  const joinedDate = intl.formatDate(attributes?.created, {
    month: 'long',
    year: 'numeric',
  });
  const responseHour = Number((attributes?.average_response_time || 0) / 3600);
  const responseRate = Number((attributes?.response_percent || 0) * 100).toFixed(0);
  const isOwner = attributes.owner;
  const isDealer = attributes.dealer;
  const isPro = attributes.pro;
  const responseTimeStr = formatResponseTime(attributes?.average_response_time || 0);

  const renderFeedbackItem = (label: string, value = 0) => {
    if (!value) {
      return null;
    }
    return (
      <div className="flex items-center justify-between max-w-xs mx-auto mb-4">
        <h5 className="font-medium body-sm">{label}</h5>
        <Rating iconClassName="h-3 w-3" className="text-gray-800 body-base" number={value} />
      </div>
    );
  };

  return (
    <div data-testid="owner-info">
      {!!attributes?.owner_score && (
        <span
          className="flex justify-center font-bold text-gray-800 text-800 md:highlight md:font-normal body-lg"
          data-testid="owner-rating">
          <Rating iconClassName="h-6 w-6" number={attributes?.owner_score || 0} />
        </span>
      )}
      <div className="flex flex-col items-center justify-center mt-2 text-center text-gray-800 body-sm">
        {isOwner && (
          <span>{intl.formatMessage({ defaultMessage: 'Outdoorsy member', id: 'XtDhve' })}</span>
        )}
        <span aria-label={isDealer ? 'dealer' : 'host'} data-testid="owner-type">
          {isDealer &&
            isPro &&
            intl.formatMessage(
              { defaultMessage: 'Pro Dealer since {joinedDate}', id: '7D7MyT' },
              {
                joinedDate,
              },
            )}
          {isDealer &&
            !isPro &&
            intl.formatMessage(
              { defaultMessage: 'Dealer since {joinedDate}', id: '0gy0rg' },
              {
                joinedDate,
              },
            )}
          {isOwner &&
            !isDealer &&
            !isPro &&
            intl.formatMessage(
              { defaultMessage: 'Host since {joinedDate}', id: '5Yw2Fg' },
              {
                joinedDate,
              },
            )}
        </span>
      </div>
      {!!averageRating?.communication_score && <Divider className="my-6" />}
      {renderFeedbackItem(
        intl.formatMessage({ defaultMessage: 'Helpfulness', id: 'z3mDm6' }),
        attributes.helpfulness_score,
      )}
      {renderFeedbackItem(
        intl.formatMessage({ defaultMessage: 'Communication', id: 'n5D/Ku' }),
        averageRating?.communication_score,
      )}
      {renderFeedbackItem(
        intl.formatMessage({ defaultMessage: 'Key exchange', id: 'dI+I2G' }),
        attributes.key_exchange_score,
      )}
      {responseHour > 0 && <Divider className="my-6" />}
      <div className="flex flex-col items-center justify-center mt-4 text-center text-gray-800 body-sm">
        {responseHour > 0 && (
          <>
            <span aria-label={`${responseRate}% response rate`}>
              {intl.formatMessage(
                { defaultMessage: '{responseRate}% response rate', id: 'fHA75a' },
                {
                  responseRate,
                },
              )}
            </span>
            {!!responseTimeStr && <span>{responseTimeStr}</span>}
          </>
        )}
      </div>
    </div>
  );
};

export default OwnerInfo;
