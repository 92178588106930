import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import Divider from '@/components/switchback/Divider/Divider';
import Fade from '@/components/switchback/Transition/Fade';
import { ListingTile } from '@/components/ui/ListingTile';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useFavorites from '@/hooks/useFavorites';
import {
  ownerIdSelector,
  ownerInfoSelector,
  ownerRentalMetaSelector,
  ownerRentalsLoading,
  ownerRentalsSelector,
  ownerStaysLoading,
  ownerStaysMetaSelector,
  ownerStaysSelector,
} from '@/redux/modules/owner/selectors';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { EListingSource } from '@/services/analytics/listings/types';
import { IMeta } from '@/services/types/search/rentals/id';
import { IRentalTile, mapRentalsToTiles } from '@/utility/mapSearchResultToTile';

import css from './OwnerRentals.module.css';

interface OwnerRentalsProps {
  onClickRental: (rental: IRentalTile, index: number) => void;
  onChangeImage: (rental: IRentalTile, index: number, nextIndex: number) => void;
}

const OwnerRentals: React.FC<OwnerRentalsProps> = ({ onClickRental, onChangeImage }) => {
  const { isAboveDesktop } = useBreakpoint();
  const intl = useIntl();
  const favorites = useSelector(getUserFavorites);
  const ownerInfo = useSelector(ownerInfoSelector);
  const ownerId = useSelector(ownerIdSelector);
  const rentalMap = useSelector(ownerRentalsSelector);
  const rentalLoading = useSelector(ownerRentalsLoading);
  const rentalMeta = useSelector(ownerRentalMetaSelector);
  const queryParams = useSelector(getQueryParams);
  const rentals = mapRentalsToTiles({
    rentals: rentalMap || [],
    favorites,
    loading: rentalLoading,
    queryParams,
  });

  const staysMap = useSelector(ownerStaysSelector);
  const staysLoading = useSelector(ownerStaysLoading);
  const staysMeta = useSelector(ownerStaysMetaSelector);
  const stays = mapRentalsToTiles({
    rentals: staysMap || [],
    favorites,
    loading: staysLoading,
    queryParams,
  });

  const { addFavorite, removeFavorite } = useFavorites({ eventSource: EListingSource.GRID_LAYOUT });

  if (!rentalLoading && !rentals.length && !staysLoading && !stays.length) {
    return (
      <div className="mt-12">
        <h3 className="mb-6 font-medium text-left h5">
          {intl.formatMessage({
            defaultMessage: 'No RVs available to rent',
            id: 'R7D+qt',
          })}
        </h3>
        <p className="mt-4 text-gray-400 body-sm">
          {intl.formatMessage(
            {
              defaultMessage: '{name} does not currently have any available vehicles.',
              id: 'TmUUso',
            },
            {
              name:
                ownerInfo?.first_name && ownerInfo?.last_name
                  ? `${ownerInfo?.first_name} ${ownerInfo?.last_name}`
                  : intl.formatMessage({
                      defaultMessage: 'This user',
                      id: 'gkRvzM',
                    }),
            },
          )}
        </p>
      </div>
    );
  }

  const renderList = (
    list: IRentalTile[],
    metaData: IMeta | null,
    loading: boolean,
    isStay?: boolean,
  ) => {
    const rvsTitle = intl.formatMessage(
      {
        defaultMessage:
          '{total, plural, one {# RV available to rent} other {# RVs available to rent}}',
        id: '2uDCSh',
      },
      {
        total: metaData?.total,
      },
    );

    const staysTitle = intl.formatMessage(
      {
        defaultMessage:
          '{total, plural, one {# Stay available to rent} other {# Stays available to rent}}',
        id: 'q+rxcZ',
      },
      {
        total: metaData?.total,
      },
    );

    return (
      <>
        <h3 className="mb-6 font-medium text-left h5" data-testid="owner-listings">
          {isStay ? staysTitle : rvsTitle}
        </h3>
        <ul className={`${css.list} mb-0 md:mb-11 lg:mb-12`} data-testid="owner-rentals-carousel">
          {list.slice(0, 8).map((rental, index) => (
            <li
              key={rental.id}
              className={index === list.length - 1 ? 'pr-5 sm:pr-0' : ''}
              data-testid={`owner-listing-${rental.id}`}>
              <Fade duration={150}>
                <ListingTile
                  rentalTile={rental}
                  addFavorite={() => addFavorite(rental, index)}
                  removeFavorite={() => removeFavorite(rental, index)}
                  onSlideChange={nextIndex => onChangeImage(rental, index, nextIndex)}
                  target={isAboveDesktop ? '_blank' : undefined}
                  onClick={() => onClickRental(rental, index)}
                  dataTestId="search-result"
                />
              </Fade>
            </li>
          ))}
        </ul>
        {!loading && metaData && metaData?.total > 8 && (
          <div className="flex justify-center mb-12 md:mb-16">
            <Button
              data-testid="all-rentals-btn"
              className="whitespace-nowrap"
              size={ButtonSize.normal}
              variant={ButtonVariants.primaryOutlined}
              href={`/rv-search?map=false&ownerId=${ownerId}${
                isStay ? '&filter[rental_category]=stay' : ''
              }`}>
              {isStay
                ? intl.formatMessage({ defaultMessage: 'Show All Stays', id: 'uioWUa' })
                : intl.formatMessage({ defaultMessage: 'Show All RVs', id: 'PrFlsa' })}
            </Button>
          </div>
        )}
        {isStay && <Divider className="my-12" />}
      </>
    );
  };

  return (
    <div className="mt-12">
      {!!stays.length && renderList(stays, staysMeta, staysLoading, true)}
      {!!rentals.length && renderList(rentals, rentalMeta, rentalLoading)}
    </div>
  );
};

export default OwnerRentals;
