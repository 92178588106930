import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import { getAuthenticatedUser } from '@/redux/selectors/auth/user';

const EditProfile: React.FC = () => {
  const intl = useIntl();
  const user = useSelector(getAuthenticatedUser);

  const editProfileUrl = user?.owner ? `/dashboard/account/${user?.id}/edit` : '/dashboard/profile';

  return (
    <div className="relative bg-gray-700">
      <div className="flex flex-col justify-between max-w-full px-4 py-6 mx-auto  text-white sm:flex-row sm:px-8 sm:py-4 lg:px-0 lg:max-w-lg">
        <div className="mb-3 sm:mb-0">
          <h3 className="font-medium tracking-wide h6">
            {intl.formatMessage({
              defaultMessage: 'Currently viewing your public profile',
              id: '/lp64K',
            })}
          </h3>
          <p className="body-sm">
            {intl.formatMessage({
              defaultMessage:
                'This is how your profile appears to Outdoorsy website visitors and renters. ',
              id: 'UfdYfl',
            })}
          </p>
        </div>
        <Button
          size={ButtonSize.normal}
          variant={ButtonVariants.whiteOutlined}
          href={editProfileUrl}>
          {intl.formatMessage({ defaultMessage: 'Edit my profile', id: 'BTHPhf' })}
        </Button>
      </div>
    </div>
  );
};

export default EditProfile;
