import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Review from '@/components/route/listing/OwnerReviews/Review';
import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import Divider from '@/components/switchback/Divider/Divider';
import { useRouter } from '@/hooks/useRouter';
import { ownerReviewsSelector } from '@/redux/modules/owner/selectors';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getReviewOpenedImage, handleReviewLighboxChange } from '@/utility/reviews';

import css from './OwnerReviews.module.css';

interface OwnerReviewsProps {
  name?: string;
}

const OwnerReviews: React.FC<OwnerReviewsProps> = ({ name }) => {
  const intl = useIntl();
  const {
    query: { slug },
  } = useRouter();
  const queryParams = useSelector(getQueryParams);
  const reviews = useSelector(ownerReviewsSelector);
  const [queryReviewId, queryOpenedImage] = getReviewOpenedImage(queryParams);
  const reviewHref = `/pro/${slug}/reviews`;

  if (!reviews.length) {
    return (
      <div
        className="relative flex flex-col justify-center py-24 bg-gray-100 align-center lg:py-32"
        data-testid="no-reviews-error">
        <h3 className="font-medium text-center h5">
          {intl.formatMessage({
            defaultMessage: 'No reviews from Outdoorsy renters yet',
            id: '0OuASF',
          })}
        </h3>
      </div>
    );
  }

  return (
    <div className="relative max-w-full px-4 mx-auto my-8 grid grid-cols-16 sm:px-8 lg:px-0 lg:max-w-lg lg:my-16 md:my-12">
      <div className="flex flex-row items-center justify-between col-span-16 lg:col-span-4 lg:flex-col lg:justify-start">
        <h3 className="w-full font-medium text-left lg:text-center h5">
          {intl.formatMessage(
            { defaultMessage: "Memories made on trips with {name}'s RVs", id: 'T9BzyC' },
            {
              name,
            },
          )}
        </h3>
        <div className="flex justify-center mt-0 ml-4 lg:mt-6">
          <Button
            data-testid="all-reviews-btn"
            className="whitespace-nowrap"
            size={ButtonSize.normal}
            variant={ButtonVariants.primaryOutlined}
            href={reviewHref}>
            {intl.formatMessage({ defaultMessage: 'See all reviews', id: 'CilfB2' })}
          </Button>
        </div>
      </div>
      <Divider className="block my-8 col-span-16 lg:hidden" />
      <div className="col-span-16 lg:col-span-11 lg:col-end-17">
        <div className={`p-0 m-0 ${css['two-column-list']}`}>
          {reviews.map(review => (
            <div
              data-testid={`owner-reviews-section-${review.id}`}
              className="inline-block w-full mb-8 md:mb-16"
              key={review.id}>
              <Review
                name={review.name}
                date={review.date}
                photoUrl={review.photoUrl}
                ratingNumber={review.ratingNumber}
                review={review.review}
                imagesToShow={2}
                images={review.images}
                tags={review.tags || []}
                ownerAnswer={review.ownerAnswer}
                openedImage={queryReviewId === review.id ? queryOpenedImage : null}
                onLightboxChange={imageIndex => handleReviewLighboxChange(review.id, imageIndex)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OwnerReviews;
