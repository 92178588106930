import { NextPage, NextPageContext } from 'next';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import HeaderContainer from '@/components/route/checkout/Header/HeaderContainer';
import ErrorPage from '@/components/route/error/ErrorPage/ErrorPage';
import {
  EditProfile,
  Hero,
  OwnerBio,
  OwnerInfo,
  OwnerRentalPhotos,
  OwnerRentals,
  OwnerReviews,
} from '@/components/route/owner';
import HeadMeta from '@/components/static-pages/HeadMeta';
import Avatar from '@/components/switchback/Avatar';
import Divider from '@/components/switchback/Divider/Divider';
import { defaultLocale } from '@/config/locales';
import { selectAuth } from '@/redux/modules/auth';
import { fetchOwner, fetchOwnerRentals } from '@/redux/modules/owner/actions';
import {
  ownerAverageRating,
  ownerIdSelector,
  ownerInfoSelector,
  ownerRentalMetaSelector,
} from '@/redux/modules/owner/selectors';
import { TRootState } from '@/redux/rootReducer';
import { getCurrency } from '@/redux/selectors/currency';
import { getFlexibleDateRangeSelected } from '@/redux/selectors/queryParams';
import {
  trackListingImageViewedEvent,
  trackListingSelectedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import { trackOwnerProfileViewed } from '@/services/analytics/owner-profile';
import { trackEvent } from '@/services/track-event';
import { IRentalTile } from '@/utility/mapSearchResultToTile';
import { IStore } from '@/utility/redux/store';

interface IError {
  statusCode: number;
  message?: string;
}

interface IProps {
  error?: IError | null;
}

const OwnerProfile: NextPage<IProps> = ({ error }) => {
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const ownerInfo = useSelector(ownerInfoSelector);
  const ownerId = useSelector(ownerIdSelector);
  const averageRating = useSelector(ownerAverageRating);
  const auth = useSelector(selectAuth);
  const reviews = useSelector((state: TRootState) => state.owner.reviews.data);
  const listings = useSelector((state: TRootState) => state.owner.rentals.data);
  const rentalMeta = useSelector(ownerRentalMetaSelector);
  const isMyProfile = auth.isAuthenticated && auth.user?.id.toString() === ownerId;
  const flexibleDateRangeSelected = useSelector(getFlexibleDateRangeSelected);
  const intl = useIntl();

  const onClickRental = (rental: IRentalTile, index: number) => {
    trackEvent({
      event: 'click/card/rental',
      rental_id: rental.id,
      source: 'profile',
    });
    if (rental?.id) {
      trackListingSelectedEvent({
        rental,
        eventSource: EListingSource.GRID_LAYOUT,
        listingPlacement: index + 1,
        flexibleDateRange: flexibleDateRangeSelected,
      });
    }
  };

  const onChangeImage = (rental: IRentalTile, index: number, nextIndex: number) => {
    if (rental?.id) {
      trackListingImageViewedEvent(rental, 'owner_profile', index + 1, nextIndex + 1);
    }
  };

  useEffect(() => {
    if (ownerId && currency !== defaultLocale.base_currency) {
      // check if the currency date is loaded to redux
      dispatch(fetchOwnerRentals(Number(ownerId)));
    }
  }, [currency, dispatch, ownerId]);

  useEffect(() => {
    if (ownerId && averageRating && rentalMeta) {
      trackOwnerProfileViewed({
        viewedProfileID: parseInt(ownerId),
        isSameUserProfile: isMyProfile,
        ownerType: ownerInfo?.dealer ? 'dealer' : 'host',
        reviewsAverage: averageRating?.score,
        numberOfListings: listings.length,
        totalListingsFound: rentalMeta.total,
        reviewsCount: reviews.length,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <>
        <HeaderContainer hasError />
        <ErrorPage statusCode={error.statusCode} message={error.message} />
      </>
    );
  }

  return (
    <>
      <HeadMeta
        title={intl.formatMessage({
          defaultMessage: 'Owner Profile',
          id: 'ZsfBFL',
        })}
        noindex
      />
      <main className="overflow-hidden text-gray-900 text">
        {isMyProfile && <EditProfile />}
        <Hero bgImage={ownerInfo?.mast_image_url} />
        <div className="relative max-w-full px-4 mx-auto grid grid-cols-16 sm:px-8 lg:px-0 lg:max-w-lg">
          <div className="col-span-16 md:col-span-4">
            <div className="-mt-16 lg:-mt-28 md:-mt-15">
              <div className="w-32 mx-auto border-2 border-white rounded-full md:border-4 md:w-full">
                <Avatar
                  className="border-white"
                  size="128"
                  photoUrl={ownerInfo?.avatar_url}
                  name={
                    ownerInfo?.first_name ||
                    intl.formatMessage({
                      defaultMessage: 'Unknown',
                      id: '5jeq8P',
                    })
                  }
                />
              </div>
              <div className="hidden px-2 mb-4 lg:px-4 md:block">
                <OwnerInfo attributes={ownerInfo || {}} averageRating={averageRating} />
              </div>
            </div>
          </div>
          <div className="col-span-16 md:col-span-11 md:col-end-17">
            <OwnerBio
              title={`${ownerInfo?.first_name || ''} ${ownerInfo?.last_name || ''}`.trim()}
              description={ownerInfo?.business_description || ownerInfo?.description}
            />
            <div className="block px-8 py-6 mt-12 bg-gray-100 rounded-lg md:hidden">
              <OwnerInfo attributes={ownerInfo || {}} averageRating={averageRating} />
            </div>
            <Divider className="my-12" />
            <OwnerRentals onClickRental={onClickRental} onChangeImage={onChangeImage} />
          </div>
        </div>
        <div className="relative h-88" data-testid="owner-photos">
          <OwnerRentalPhotos />
        </div>
        <OwnerReviews
          name={`${ownerInfo?.first_name || ''} ${ownerInfo?.last_name || ''}`.trim()}
        />
      </main>
    </>
  );
};

OwnerProfile.getInitialProps = async ({
  res,
  query,
  reduxStore,
}: NextPageContext & { reduxStore: IStore }) => {
  const { slug } = query;
  const state = reduxStore.getState();
  const owner = state.owner;

  if (typeof slug !== 'string' || !slug) {
    if (res) res.statusCode = 404;
    return { error: { statusCode: 404 } };
  }

  if (!owner.rentals.data.length) {
    await reduxStore.dispatch(fetchOwner(slug));
  }

  return {};
};

export default OwnerProfile;
