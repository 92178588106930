export enum EOwnerProfileEventName {
  PROFILE_VIEWED = 'Profile Viewed',
}

export interface IOwnerProfileEvent {
  viewedProfileID: number;
  isSameUserProfile: boolean;
  ownerType: 'host' | 'dealer';
  numberOfListings: number;
  totalListingsFound: number;
  reviewsCount: number;
  reviewsAverage: number;
}
