import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import OutdoorsyLogo from '@/assets/images/outdoorsy-logo.svg';
import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link, { LinkVariants } from '@/components/switchback/Link/Link';

import css from './Header.module.css';

interface IProps {
  backLinkUrl?: string;
  backLinkTitle?: string;
  hasError?: boolean;
}

const Header: React.FC<IProps> = ({ backLinkUrl, backLinkTitle, hasError }) => {
  const intl = useIntl();

  const labels = {
    backLink: intl.formatMessage({
      defaultMessage: 'Back',
      id: '6IknZZ',
      description: 'Checkout > Header > Back to listing link label',
    }),
  };

  return (
    <>
      <header
        data-testid="checkout-header"
        className="relative w-full bg-white border-b border-gray-200 lg:bg-canvas-100 lg:border-none">
        <div className="items-center px-4 py-6 lg:px-0 grid grid-cols-main container-main gap-x-default">
          {!hasError && (
            <Link
              className="flex text-left lg:hidden"
              href={backLinkUrl || ''}
              title={backLinkTitle}>
              <Icon width={20} name={CARET_LARGE} />
            </Link>
          )}
          <h5 className="flex items-center justify-center h5 col-start-2 col-span-4 md:col-start-4 lg:col-start-7 lg:hidden">
            <FormattedMessage defaultMessage="Book your trip" id="udndLR" />
          </h5>

          <div className={`hidden lg:block text-left col-start-1 col-span-3 ${css.logo}`}>
            <OutdoorsyLogo />
            <span className="sr-only">Outdoorsy</span>
          </div>
        </div>
      </header>
      <div className="relative hidden px-4 my-4 lg:px-0 lg:grid grid-cols-main container-main gap-x-default">
        <div className="justify-start col-span-5 col-start-1 ">
          {!hasError && (
            <Link href={backLinkUrl || ''} title={backLinkTitle} variant={LinkVariants.noUnderline}>
              <Icon width={18} name={CARET_LARGE} />
              <span className="ml-2 body-sm">{labels.backLink}</span>
            </Link>
          )}
          <h5 className="mt-3 autoType800 highlight">
            <FormattedMessage defaultMessage="Book your trip" id="udndLR" />
          </h5>
        </div>
      </div>
    </>
  );
};

export default Header;
