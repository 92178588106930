import React from 'react';
import { useSelector } from 'react-redux';

import PhotoGallery from '@/components/ui/PhotoGallery/PhotoGallery';
import {
  ownerRentalImagesSelector,
  ownerStayImagesSelector,
} from '@/redux/modules/owner/selectors';

const OwnerRentalPhotos: React.FC = () => {
  const rvImages = useSelector(ownerRentalImagesSelector);
  const stayImages = useSelector(ownerStayImagesSelector);
  return <PhotoGallery images={[...stayImages, ...rvImages]} />;
};

export default OwnerRentalPhotos;
