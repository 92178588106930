import React from 'react';
import { useIntl } from 'react-intl';

import Avatar from '@/components/switchback/Avatar';
import { getAbbreviatedSurname } from '@/utility/formatters';

import Rating from '../../../Rating/Rating';

type IRenterProfileElement = React.HTMLAttributes<HTMLDivElement>;

export interface IProps {
  name: string;
  date: Date;
  ratingNumber?: number;
  photoUrl?: string;
}

const RenterProfile: React.FC<IProps & IRenterProfileElement> = ({
  name,
  date,
  ratingNumber,
  photoUrl,
  ...props
}) => {
  const intl = useIntl();
  const formattedDate = intl.formatDate(date, {
    month: 'long',
    year: 'numeric',
  });
  const renterName = name
    ? getAbbreviatedSurname(name)
    : intl.formatMessage({
        defaultMessage: 'Anonymous Renter',
        id: 'gJQnRW',
      });

  return (
    <div data-testid="renter-profile" className="flex items-center leading-none text" {...props}>
      <div className="w-10 mr-2">
        <Avatar name={renterName} photoUrl={photoUrl} size="40" />
      </div>
      <div>
        <p className="font-semibold leading-none h6">{renterName}</p>

        <p className="flex flex-wrap items-center mt-1">
          {ratingNumber !== undefined && (
            <>
              <Rating
                className="ml-2 font-medium text-gray-800 text body-sm"
                number={ratingNumber}
              />
              <span aria-hidden="true" className="mx-2 font-bold text-gray-700">
                {'•'}
              </span>
            </>
          )}
          <span className="leading-none text-gray-500 mr-1.5 body-sm">{formattedDate}</span>
        </p>
      </div>
    </div>
  );
};

export default RenterProfile;
