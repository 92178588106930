import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import RenterProfile, {
  IProps as IRenterProfileProps,
} from '@/components/route/listing/OwnerReviews/Review/RenterProfile/RenterProfile';
import ClampedText from '@/components/switchback/ClampedText/ClampedText';
import Lightbox from '@/components/switchback/Lightbox';
import Tag, { TagSizes } from '@/components/switchback/Tag/Tag';
import Text from '@/components/switchback/Text/Text';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';

import css from './Review.module.css';

export interface IProps extends IRenterProfileProps {
  ratingNumber: number;
  review: string;
  imagesToShow?: number;
  images?: string[];
  tags?: string[];
  ownerAnswer?: {
    name: string;
    answer: string;
  };
  openedImage?: number | null;
  onLightboxChange?: (imageIndex: number | null) => void;
  date: Date;
}

const Review: React.FC<IProps> = ({
  name,
  date,
  ratingNumber,
  photoUrl,
  review,
  imagesToShow,
  images = [],
  tags = [],
  ownerAnswer,
  openedImage,
  onLightboxChange,
}) => {
  const intl = useIntl();
  const [internalOpenedImage, setInternalOpenedImage] = useState<number | null>(null);

  const hiddenImages =
    imagesToShow && images.length > imagesToShow ? images.length - imagesToShow : 0;
  const isLightboxControlled = typeof openedImage !== 'undefined';
  const currentOpenedImage = isLightboxControlled ? openedImage : internalOpenedImage;
  const isLightboxOpen = currentOpenedImage !== null;
  const activeSlide = currentOpenedImage || 0;

  const handleLightboxChange = (index: number | null) => {
    if (isLightboxControlled) {
      onLightboxChange?.(index);
      return;
    }
    setInternalOpenedImage(index);
  };

  return (
    <div data-testid="listing-review">
      <RenterProfile name={name} date={date} ratingNumber={ratingNumber} photoUrl={photoUrl} />
      <div className={`mt-5 text-gray-900 lg:mt-4 body-base ${css.leadingReview}`}>
        <ClampedText
          clamp={[5, 4, 3]}
          paragraphClasses="mb-4"
          buttonClassName="text-gray-800 font-medium"
          readLess
          linkWithIcon
          underline={false}
          readMore>
          {review}
        </ClampedText>
      </div>
      {ownerAnswer && (
        <div data-testid="listing-review-owner-answer" className="mt-5 lg:mt-4">
          <Text
            className="font-medium text-gray-900 font-highlight text-400 leading-300"
            type="paragraph">
            {intl.formatMessage(
              { defaultMessage: '{name}’s answer', id: 'w8Vgk3' },
              { name: ownerAnswer.name },
            )}
          </Text>
          <div className={`text-gray-900 body-base ${css.leadingReview} lg:mt-1`}>
            <ClampedText
              clamp={[5, 4, 3]}
              paragraphClasses="mb-4"
              buttonClassName="text-gray-800 font-medium"
              readLess
              linkWithIcon
              underline={false}
              readMore>
              {ownerAnswer.answer}
            </ClampedText>
          </div>
        </div>
      )}
      {!!images.length && (
        <>
          <Text type="paragraph" className="mt-5 font-normal text-gray-900 text text-200">
            {intl.formatMessage(
              {
                defaultMessage:
                  '{photosAmount, plural, one {# Photo} other {# Photos}} submitted by {name}',
                id: 'sPsDjQ',
              },
              { photosAmount: images.length, name },
            )}
          </Text>
          <div
            data-testid="listing-review-photos"
            className="flex px-4 pb-1 mt-1 -mx-4 overflow-auto md:mx-0 md:px-0">
            {images.slice(0, imagesToShow).map((image, index) => (
              <div key={image} className="flex-none">
                <button
                  aria-label={intl.formatMessage(
                    {
                      defaultMessage:
                        'Open gallery overlay starting with review photo {photoPosition}',
                      id: 'V/TwJ4',
                    },
                    { photoPosition: index + 1 },
                  )}
                  className="mr-2.5"
                  onClick={() => handleLightboxChange(index)}>
                  <ResponsiveImage
                    className="w-20 h-20 rounded md:w-15 md:h-15"
                    sourceSizes={['square80']}
                    sizes="80px, (min-width: 768px) 60px"
                    src={image}
                    alt={intl.formatMessage(
                      { defaultMessage: 'Review photo {photoPosition}', id: 'E6Wrwl' },
                      { photoPosition: index + 1 },
                    )}
                  />
                </button>
              </div>
            ))}
            {!!hiddenImages && (
              <button
                aria-label={intl.formatMessage(
                  {
                    defaultMessage:
                      'Open gallery overlay starting with review photo {photoPosition}',
                    id: 'V/TwJ4',
                  },
                  { photoPosition: images.length - hiddenImages + 1 },
                )}
                data-testid="listing-review-photos-hidden-images"
                className="flex items-center justify-center flex-none w-20 h-20 font-normal text-gray-800 border-2 border-green-800 rounded md:w-15 md:h-15 mr-2.5 highlight text-200"
                onClick={() => handleLightboxChange(images.length - hiddenImages)}>
                +{hiddenImages}
              </button>
            )}
          </div>
          <Lightbox
            infinite
            adaptiveHeight
            open={isLightboxOpen}
            initialSlide={activeSlide}
            activeSlide={activeSlide}
            beforeSlideChange={(_oldIndex, newIndex) => handleLightboxChange(newIndex)}
            onClose={() => handleLightboxChange(null)}>
            {images.map((image, index) => (
              <ResponsiveImage
                className={`${css.lightboxImage} mx-auto rounded`}
                sourceSizes={['landscape1440']}
                cropMode="fit"
                key={image}
                src={image}
                alt={intl.formatMessage(
                  { defaultMessage: 'Review photo {photoPosition}', id: 'E6Wrwl' },
                  { photoPosition: index + 1 },
                )}
              />
            ))}
          </Lightbox>
        </>
      )}
      {!!tags.length && (
        <div data-testid="listing-review-tags" className="mt-2">
          {tags.map(tag => (
            <Tag
              key={tag}
              data-testid={`${tag.replace(' ', '-').toLowerCase()}-tag`}
              className="mt-3 mr-2"
              size={TagSizes.large}>
              {tag}
            </Tag>
          ))}
        </div>
      )}
    </div>
  );
};

export default Review;
